import { useEffect, useState } from "react";
import {
  createProperty,
  getPropertyDetails,
  updateProperty,
} from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { countries } from "countries-list";
import { Button, DatePicker, Input, Select, Upload } from "antd";
import { EP_URL, PropertyList } from "../../api/apiTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { UploadOutlined } from "@ant-design/icons";
import { AiOutlineDelete } from "react-icons/ai";
import { CgAttachment } from "react-icons/cg";
import { nanoid } from 'nanoid'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TextArea from "antd/es/input/TextArea";
import {projectTypes} from "../../types/components";

dayjs.extend(utc);

function ProjectAddEdit() {
  const nav = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState<PropertyList>();
  const [errors, setErrors] = useState<any>({});
  const countryNames = Object.values(countries).map((country) => ({
    label: country.name,
    value: country.name,
  }));
  const [isLoading, setLoading] = useState(false);

  const [image, setImage] = useState<any>();
  const [fileImage, setFileImage] = useState<any[]>([]);
  const [fileCheck, setFileCheck] = useState<any[]>([]);
  const [fileVersion, setFileVersion] = useState<any[]>([]);
  const [fileSign, setFileSign] = useState<any[]>([]);
  const [fileInitial, setFileInitial] = useState<any[]>([]);
  const [fileFinal, setFileFinal] = useState<any[]>([]);
  const [fileOther, setFileOther] = useState<any[]>([]);

  function setField(field: string, value: any) {
    const tmp: any = {};
    tmp[field] = value;
    setFormData(Object.assign({}, formData, tmp));
  }

  async function submit() {
    const checkFields = [
      "id",
      "name",
      "proponent",
      "type",
      "country",
      "region",
      "startDate",
      "endDate",
    ];

    if (formData?.isPublish) {
      const errs: any = {};
      checkFields.forEach((field: string) => {
        if (!formData?.[field]) {
          errs[field] = true;
        }
      });
      setErrors(errs);
      if (Object.keys(errors).length > 0) {
        console.log("check failed", errors, Object.keys(errors).length);
        return;
      }
    }

    setLoading(true);

    // Upload files and images
    const uploadedFileList = await handleUpload();

    const parseUploadedFileList = (list: any[], fileType: string) => {
      return [
        ...((formData?.[fileType] as any[]) ?? []),
        ...uploadedFileList
          ?.filter((file: any) =>
            list.find((f: any) => file.filename.includes(f?.filename)),
          )
          ?.map((file: any) => {
            const localFile: any = list?.find((f: any) =>
              file?.filename.includes(f.filename),
            );
            if (localFile) {
              return {
                ...file,
                size: localFile?.size,
                date: localFile?.lastModifiedDate,
              };
            }
          }) || [],
      ];
    };

    const imageFiles = parseUploadedFileList(fileImage, "imageUrl");
    const checkFiles = parseUploadedFileList(fileCheck, "check");
    const versionFiles = parseUploadedFileList(fileVersion, "version");
    const signFiles = parseUploadedFileList(fileSign, "sign");
    const initialFiles = parseUploadedFileList(fileInitial, "initial");
    const finalFiles = parseUploadedFileList(fileFinal, "final");
    const otherFiles = parseUploadedFileList(fileOther, "other");

    if (formData?._id) {
      await updateProperty({
        ...formData,
        imageUrl: imageFiles,
        check: checkFiles,
        version: versionFiles,
        sign: signFiles,
        initial: initialFiles,
        final: finalFiles,
        other: otherFiles,
      });
    } else {
      await createProperty({
        ...formData,
        imageUrl: imageFiles,
        check: checkFiles,
        version: versionFiles,
        sign: signFiles,
        initial: initialFiles,
        final: finalFiles,
        other: otherFiles,
      });
    }

    setLoading(false);
    if (formData?.id) {
      nav(`/manage-detail/${formData?.id}`);
    } else {
      nav("/manage");
    }
  }

  useEffect(() => {
    async function fetchPropertyDetails() {
      if (id) {
        const propertyDetails = await getPropertyDetails(Number(id));
        if (propertyDetails) {
          setFormData(propertyDetails.post);
          setImage(propertyDetails?.post?.imageUrl?.[0]?.path);
        }
      }
    }
    fetchPropertyDetails();
  }, [id]);

  // TODO: This is from old add page, the DB does not carry these fields
  // async function fetchCount() {
  //   const getCounts = await getCount();
  //   setField("carbonDebitsPerYear", getCounts?.post?.[0]?.carbonDebits || 0);
  //   setField("plotSize", getCounts?.post?.[0]?.plotSize || 0);
  // }
  //
  // useEffect(() => {
  //   fetchCount();
  // }, []);

  ///////////////////////
  function handleFileUpload(field: string, info: any) {
    if (info.fileList.some((f: any) => f.size > 1024*1024*4.5)) {
      toast.error('File must smaller than 4.5MB!');
      return;
    }
    const tmp = info.fileList.map((f :any) => {
      f['filename'] = Date.now() + "-" + nanoid(6) + "-" + f.name;
      return f
    })

    switch (field) {
      case "imageUrl":
        setFileImage(tmp);
        setImage(URL.createObjectURL(info.file));
        break;
      case "check":
        setFileCheck(tmp);
        break;
      case "version":
        setFileVersion(tmp);
        break;
      case "sign":
        setFileSign(tmp);
        break;
      case "initial":
        setFileInitial(tmp);
        break;
      case "final":
        setFileFinal(tmp);
        break;
      case "other":
        setFileOther(tmp);
        break;
    }
  }

  const handleUpload = async () => {
    const uploaded: any[] = [];

    const uploadEach = async (list: any) => {
      if (!list?.length) return [];
      const responses: any[] = [];
      for (let f of list) {
        const formData = new FormData();
        formData.append("files", f.originFileObj, f.filename)
        await axios.post(`${EP_URL}/api/upload`, formData)
          .then(response => {
            responses.push(...response.data)
          })
          .catch(err => {
            if (err.status === 413) {
              toast.error(`File ${f.filename} too big!`);
            } else {
              toast.error("An error occurred while uploading files!");
            }
            console.error("Error uploading files", err);
            return [];
          })
      }
      return responses;
    }

    const promises = [];
    promises.push(uploadEach(fileImage).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileCheck).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileVersion).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileSign).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileInitial).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileFinal).then(res => uploaded.push(...res)));
    promises.push(uploadEach(fileOther).then(res => uploaded.push(...res)));

    await Promise.all(promises);
    
    return uploaded;
  };

  const updateFileListState = (fileType: any, fileUid: any) => {
    switch (fileType) {
      case "imageUrl":
        setFileImage((prevList: any) =>
          prevList.filter((f: any) => f.uid !== fileUid),
        );
        break;
      case "check":
        setFileCheck((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      case "version":
        setFileVersion((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      case "sign":
        setFileSign((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      case "initial":
        setFileInitial((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      case "final":
        setFileFinal((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      case "other":
        setFileOther((prevList) => prevList.filter((f) => f.uid !== fileUid));
        break;
      default:
        console.error(`Unknown file type: ${fileType}`);
        toast.error("Unknown file type.");
        break;
    }
  };

  const handleDeleteFile = async (file: any, fileType: any) => {
    if (file.path) {
      try {
        const response = await axios.post(
          `${EP_URL}/api/post/deletePostFile/${formData?._id}`,
          {
            fileName: file.filename,
            path: file.path,
          },
        );

        if (response.data.msg === "File deleted successfully") {
          setField(fileType, response.data.post[fileType]);
          toast.success("File deleted successfully!");
        } else {
          toast.error("Failed to delete the file.");
        }
      } catch (error) {
        console.error("Error deleting the file from the server:", error);
        toast.error("Error deleting the file.");
      }
    } else {
      updateFileListState(fileType, file.uid);
    }
  };

  ///////////////////////

  return (
    <>
      <div>
        <div className={"grid grid-cols-2 gap-4 max-w-3xl mx-auto"}>
          <div className="col-span-2">
            <div className="input-label">Country Image</div>
            {image && (
                <img
                    src={image}
                    className="mr-auto max-h-[350px] max-w-[50%]"
                    alt="cover"
                />
            )}
          </div>

          <div className="max-w-[400px] w-full">
            <div className="input-label">Cover image</div>
            <Upload
                className="bg-white w-full"
                fileList={fileImage}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("imageUrl", info)}
                onRemove={async (file) => {
                  await handleDeleteFile(file, "imageUrl");
                  return false;
                }}
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                {" "}
              </Button>
            </Upload>
            {formData?.imageUrl?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "imageUrl")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
          </div>

          <div>
            <div className="input-label">ID Number</div>
            <Input
                type="number"
                placeholder="ID..."
                value={formData?.id}
                onChange={(e) => setField("id", Number(e.target.value))}
                className="bg-white h-[40px] rounded"
            />
            {errors?.["id"] && (
                <div className="text-[red]">Input ID Number</div>
            )}
          </div>
          <div>
            <div className="input-label">Name</div>
            <Input
                type="text"
                placeholder="Name..."
                value={formData?.name}
                onChange={(e) => setField("name", e.target.value)}
                className="input-box"
            />
            {errors?.["name"] && <div className="text-[red]">Input Name</div>}
          </div>
          <div>
            <div className="input-label">Project Type</div>
            <Select
                className="custom-select w-full"
                onChange={(e) => setField("type", e)}
                value={formData?.type}
                style={{height: 40}}
                options={projectTypes}
            />
            {errors?.["type"] && (
                <div className="text-[red]">Input Project Type</div>
            )}
          </div>
          <div>
            <div className="input-label">Proponent</div>
            <Input
                type="text"
                placeholder="Proponent..."
                value={formData?.proponent}
                onChange={(e) => setField("proponent", e.target.value)}
                className="input-box"
            />
            {errors?.["proponent"] && (
                <div className="text-[red]">Input Proponent</div>
            )}
          </div>
          <div>
            <div className="input-label">Developer</div>
            <Input
                type="text"
                placeholder="Developer..."
                value={formData?.developer}
                onChange={(e) => setField("developer", e.target.value)}
                className="input-box"
            />
            {errors?.["developer"] && (
                <div className="text-[red]">Input Developer</div>
            )}
          </div>
          <div>
            <div className="input-label">Country</div>
            <Select
                onChange={(e) => setField("country", e)}
                value={formData?.country}
                showSearch
                defaultValue="Brazil"
                style={{height: 40}}
                className="w-full"
                variant={"outlined"}
                options={countryNames}
            />
            {errors?.["country"] && (
                <div className="text-[red]">Select Country</div>
            )}
          </div>
          <div>
            <div className="input-label">Region</div>
            <Input
                value={formData?.region}
                onChange={(e) => setField("region", e.target.value)}
                type="text"
                placeholder="Region..."
                className="input-box"
            />
            {errors?.["region"] && (
                <div className="text-[red]">Select Region </div>
            )}
          </div>
          {formData?._id && (
              <>
                <div className={"col-span-2"}>
                  <div className="input-label">Contract address</div>
                  <div className={"flex flex-row"}>
                    <Input
                        type="text"
                        placeholder="0x address..."
                        value={formData?.address}
                        onChange={(e) => setField("address", e.target.value)}
                        style={{
                          height: 40,
                          borderRadius: 8,
                          backgroundColor: "white",
                        }}
                    />
                    {errors?.["address"] && (
                        <div className="text-[red]">Input contract address</div>
                    )}
                    <Button
                        style={{width: 150, height: 40}}
                        disabled={
                            !formData?.address || formData?.address?.length < 42
                        }
                        onClick={() => getPropertyDetails(formData?.id)}
                        className={"ml-2"}
                    >
                      Fetch data
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="input-label">Batch number</div>
                  <Input
                      type="number"
                      placeholder="Batch number..."
                      value={formData?.batchNumber}
                      onChange={(e) => setField("batchNumber", e.target.value)}
                      readOnly
                      style={{
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "white",
                      }}
                  />
                  {errors?.["batchNumber"] && (
                      <div className="text-[red]">
                        Input contract address and click fetch data
                      </div>
                  )}
                </div>
                <div>
                  <div className="input-label">Initial supply</div>
                  <Input
                      type="number"
                      placeholder="Initial supply..."
                      value={formData?.initialSupply}
                      onChange={(e) => setField("initialSupply", e.target.value)}
                      readOnly
                      style={{
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "white",
                      }}
                  />
                  {errors?.["initialSupply"] && (
                      <div className="text-[red]">
                        Input contract address and click fetch data
                      </div>
                  )}
                </div>
                <div>
                  <div className="input-label">Plant time</div>
                  <DatePicker
                      className="w-full"
                      readOnly
                      disabled={true}
                      showTime={true}
                      style={{
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "white",
                      }}
                      value={
                        formData?.plantTime ? dayjs.utc(formData?.plantTime) : null
                      }
                  />
                  {errors?.["plantTime"] && (
                      <div className="text-[red]">
                        Input contract address and click fetch data
                      </div>
                  )}
                </div>
                <div>
                  <div className="input-label">Batch Mint Limit</div>
                  <Input
                      type="number"
                      placeholder="Batch mint limit..."
                      value={formData?.batchMintLimit}
                      readOnly
                      style={{
                        height: 40,
                        borderRadius: 8,
                        backgroundColor: "white",
                      }}
                  />
                  {errors?.["batchMintLimit"] && (
                      <div className="text-[red]">
                        Input contract address and click fetch data
                      </div>
                  )}
                </div>
              </>
          )}
          <div>
            <div className="input-label">
              Plot Size (in sq m<sup>2</sup>)
            </div>
            <Input
                type="number"
                step={0.01}
                placeholder="Plot Size..."
                value={formData?.plotSize}
                onChange={(e) => setField("plotSize", Number(e.target.value))}
                className="input-box"
            />
            {errors?.["plotSize"] && (
                <div className="text-[red]">Input Size plot</div>
            )}
          </div>
          <div>
            <div className="input-label">System size</div>
            <Input
                type="number"
                step={0.01}
                placeholder="System size..."
                value={formData?.systemSize}
                onChange={(e) => setField("systemSize", Number(e.target.value))}
                className="input-box"
            />
            {errors?.["systemSize"] && (
                <div className="text-[red]">Input System size</div>
            )}
          </div>
          <div>
            <div className="input-label">Carbon Debits Per Year</div>
            <Input
                type="number"
                placeholder="Carbon Debits..."
                value={formData?.carbonDebitsPerYear}
                onChange={(e) =>
                    setField("carbonDebitsPerYear", Number(e.target.value))
                }
                className="input-box"
            />
            {errors?.["carbonDebitsPerYear"] && (
                <div className="text-[red]">Input Carbon Debits</div>
            )}
          </div>
          <div>
            <div className="input-label">Carbon Debit Start</div>
            <DatePicker
                style={{
                  height: 40,
                  backgroundColor: "white",
                }}
                id="data1"
                className="w-full"
                onChange={(date) => setField("startDate", date.toDate())}
                value={formData?.startDate ? dayjs.utc(formData?.startDate) : null}
            />
            {errors?.["startDate"] && (
                <div className="text-[red]">Select Carbon Debit Start </div>
            )}
          </div>
          <div>
            <div className="input-label">Carbon Debit End</div>
            <DatePicker
                style={{
                  height: 40,
                  backgroundColor: "white",
                }}
                className="w-full"
                onChange={(date) => setField("endDate", date.toDate())}
                value={formData?.endDate ? dayjs.utc(formData?.endDate) : null}
            />
            {errors?.["endDate"] && (
                <div className="text-[red]">Select Carbon Debit End Date</div>
            )}
          </div>
          <div>
            <div className="input-label">Questionnaire</div>
            <Upload
                fileList={fileCheck}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("check", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "check");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.check?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "check")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["check"] && (
                <div className="text-[red]">Upload Questionnaire</div>
            )}
          </div>
          <div>
            <div className="input-label">GCS Version</div>
            <Upload
                fileList={fileVersion}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("version", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "version");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.version?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "version")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["version"] && (
                <div className="text-[red]">Upload GCS Version</div>
            )}
          </div>
          <div>
            <div className="input-label">Contract</div>
            <Upload
                fileList={fileSign}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("sign", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "sign");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.sign?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "sign")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["sign"] && (
                <div className="text-[red]">Upload Contract</div>
            )}
          </div>
          <div>
            <div className="input-label">Initial Estimate Report</div>
            <Upload
                fileList={fileInitial}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("initial", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "initial");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.initial?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "initial")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["initial"] && (
                <div className="text-[red]">Upload Initial Estimate Report</div>
            )}
          </div>
          <div>
            <div className="input-label">Final Report</div>
            <Upload
                fileList={fileFinal}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("final", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "final");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.final?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "final")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["final"] && (
                <div className="text-[red]">Upload Final Report</div>
            )}
          </div>
          <div>
            <div className="input-label">Other documents</div>
            <Upload
                fileList={fileOther}
                beforeUpload={() => false}
                onChange={(info) => handleFileUpload("other", info)}
                onRemove={(file) => {
                  handleDeleteFile(file, "other");
                  return false;
                }}
                className="bg-white w-full"
                multiple={true}
            >
              <Button
                  icon={<UploadOutlined/>}
                  iconPosition={"end"}
                  className="w-full h-[40px] bg-white justify-start p-2"
              >
                &nbsp;
              </Button>
            </Upload>
            {formData?.other?.map((file: any) => (
                <div
                    className="py-1 w-full flex flex-row items-center hover:bg-gray-100"
                    key={file.filename}
                >
                  <CgAttachment className="mr-2"></CgAttachment>
                  <div className="text-sm flex-grow">{file.filename}</div>
                  <AiOutlineDelete
                      onClick={() => handleDeleteFile(file, "other")}
                      className="cursor-pointer"
                  />
                </div>
            ))}
            {errors?.["other"] && (
                <div className="text-[red]">Upload Other Documents</div>
            )}
          </div>
          <div>
            <div className="input-label">Publish</div>
            <Select
                onChange={(e) => setField("isPublish", e)}
                value={formData?.isPublish}
                style={{height: 40}}
                className="w-full"
                options={[
                  {value: true, label: "Yes"},
                  {value: false, label: "No"},
                ]}
            />
          </div>
          {formData?._id && (
              <>
                <div>
                  <div className="input-label">
                    Manual entry of Carbon Debit Spent
                  </div>
                  <Select
                      onChange={(e) => setField("excludeFromWorker", e)}
                      value={formData?.excludeFromWorker}
                      style={{height: 40}}
                      className="w-full"
                      options={[
                        {value: true, label: "Yes"},
                        {value: false, label: "No"},
                      ]}
                  />
                </div>
              </>
          )}
          {formData?.excludeFromWorker && (
              <>
                <div>
                  <div className="input-label">
                    Carbon Debits Spent
                  </div>
                  <Input
                      type="number"
                      placeholder="Carbon Debits..."
                      value={formData?.carbonDebitsSpent}
                      onChange={(e) =>
                          setField("carbonDebitsSpent", Number(e.target.value))
                      }
                      className="input-box"
                  />
                  {errors?.["carbonDebitsSpent"] && (
                      <div className="text-[red]">Input Carbon Debits</div>
                  )}
                </div>
              </>
          )}
          <div>
            <div className="input-label">Information</div>
            <TextArea
                onChange={(e) => setField("information", e.target.value)}
                value={formData?.information}
                className="w-full"
            />
          </div>

          <div className="col-span-2 mb-24">
            <div className="flex flex-row w-full justify-center gap-4">
              <Button
                  style={{width: 150, height: 40}}
                  onClick={(e) => nav(-1)}
              >
                Cancel
              </Button>
              <div>
                <Button
                    onClick={submit}
                    style={{
                      width: 150,
                      height: 40,
                      backgroundColor: "#247147",
                      borderRadius: 5,
                    }}
                >
                  <div className="flex flex-row justify-between items-center">
                    {isLoading && (
                        <svg
                            version="1.1"
                            id="loader-1"
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="40px"
                            height="40px"
                            viewBox="0 0 50 50"
                            style={{margin: "auto"}}
                            className="mr-2"
                        >
                          <path
                              fill="#fff"
                              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
                          >
                            <animateTransform
                                attributeType="xml"
                                attributeName="transform"
                                type="rotate"
                                from="0 25 25"
                                to="360 25 25"
                                dur="0.6s"
                                repeatCount="indefinite"
                            />
                          </path>
                        </svg>
                    )}
                    <div
                        className="text-white font-semibold"
                    >
                      {formData?._id ? "Update" : "Create"}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectAddEdit;

import {ListingParams, PropertyList, TransactionParams, Transactions} from "../api/apiTypes";

export interface CardViewProps {
  propertyList: PropertyList[];
}

export interface DataTableViewProps {
  propertyPaginationList: PropertyList[];
  params: ListingParams;
  debitsData?: any;
  setParams: React.Dispatch<React.SetStateAction<ListingParams>>;
}

export interface DataTableTransactionViewProps {
  propertyPaginationList: Transactions[];
  params: TransactionParams;
  setParams: React.Dispatch<React.SetStateAction<TransactionParams>>;
}

export interface PaginationProps {
  _page: number;
  totalPages: number;
  className?: string;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onPageChange?: (v: number) => void;
  params: ListingParams | TransactionParams;
  setParams: React.Dispatch<React.SetStateAction<any>>;
  showXOfAll?: boolean;
}

export interface TableSearchProps {
  params: ListingParams;
  setParams: React.Dispatch<React.SetStateAction<ListingParams>>;
  loading?: boolean;
}

export interface TableSortProps {
  params: ListingParams;
  field: string;
  setParams: React.Dispatch<React.SetStateAction<ListingParams>>;
  title: string;
}

export interface TableFiltersProps {
  fetchSortedPaginatedListings: () => void;
  params: ListingParams;
  setParams: React.Dispatch<React.SetStateAction<ListingParams>>;
}

export const projectTypes = [
  {value: "Agroforestry", label: "Agroforestry"},
  {value: "Reforestation", label: "Reforestation"},
  {value: "Compost", label: "Compost"},
]